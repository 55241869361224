export const orderStatus = (status) => {
  switch (true) {
    case status === "REGISTERED":
      return "주문등록 완료";
    case status === "PROCESSING":
      return "주문 처리 중";
    case status === "DONE":
      return "발송완료";
    case status === "CANCEL":
      return "주문취소";

    default:
      return "해당되는 단계가 없습니다";
  }
};
