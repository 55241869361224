import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { orderStatus } from "../../utils/ConvertWord";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function Cancel() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [count, setCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (receiver) => {
    setTooltipOpen({
      ...tooltipOpen,
      [receiver]: !tooltipOpen[receiver],
    });
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글

  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  useEffect(() => {
    const excelListData = async () => {
      try {
        const encodedWholesaler = encodeURIComponent(
          cookies.get("info").profile
        );
        const resList = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/manage/order?status=CANCEL&page=${currentPage}&limit=${pageSize}&wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
        if (resList.data.result === "success") {
          setData(resList.data.data.list);
          setCount(resList.data.data.count.count);
          setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
        }
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
      }
    };
    excelListData();
  }, [currentPage, pageSize]);

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items, delivery_fee) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });

    // 배송료 추가
    if (delivery_fee) {
      amount += parseFloat(delivery_fee);
    }
    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      resValue =
        "$" +
        parseFloat(unitPrice * qauntity)
          .toFixed(2)
          .toString();
    }

    return resValue;
  };

  return (
    <NormalLayout>
      <div>
        <h3>주문취소 {count && count !== 0 ? <>({count}건)</> : <></>}</h3>
      </div>
      <div className="p-3"></div>
      {data ? (
        <>
          <div className="border p-3">
            <div className="mb-3">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color="dark">
                  페이지당 노출 수: {pageSize}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => selectPageSize(10)}>
                    10개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(30)}>
                    30개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(50)}>
                    50개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(100)}>
                    100개
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>{" "}
            </div>
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">주문번호</th>
                  <th className="text-center p-2 border">등록일</th>
                  <th className="text-center p-2 border">요청메모</th>
                  <th className="text-center p-2 border">이름</th>
                  {/* <th className="text-center p-2 border">전화번호</th>
                  <th className="text-center p-2 border">모바일번호</th>
                  <th className="text-center p-2 border">통관부호</th>
                  <th className="text-center p-2 border">우편번호</th>
                  <th className="text-center p-2 border">주소</th> */}
                  <th className="text-center p-2 border">배송메모</th>
                  <th className="text-center p-2 border">주문상품</th>

                  <th className="text-center p-2 border">무게</th>
                  <th className="text-center p-2 border">배송료</th>
                  <th className="text-center p-2 border">총액</th>
                  <th className="text-center p-2 border">운송장번호</th>
                </tr>
              </thead>
              <tbody>
                {data.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center cell">{o.order_no}</td>
                      <td className="text-center">
                        <Moment format="YYYY-MM-DD">{o.created_at}</Moment>
                      </td>
                      <td className="text-center">{o.shipper_memo}</td>
                      <td className="text-center" style={{ cursor: "pointer" }}>
                        {" "}
                        <span id={`Tooltip-${o.order_index}`}>
                          {" "}
                          {o.customer_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[o.order_index]}
                          target={`Tooltip-${o.order_index}`}
                          toggle={() => toggleTooltip(o.order_index)}
                        >
                          <div className="text-start">
                            <li>통관부호 : {o.pcc}</li>
                            <li>전화번호 : {o.customer_tel}</li>
                            <li>모바일 : {o.customer_mobile}</li>
                            <li>
                              주소 : {o.address}, #{o.zip}
                            </li>
                          </div>
                        </Tooltip>
                      </td>
                      {/* <td className="text-center">{o.customer_tel}</td>
                      <td className="text-center">{o.customer_mobile}</td>
                      <td className="text-center">{o.pcc}</td>

                      <td className="text-center">{o.zip}</td>
                      <td>{o.address}</td> */}

                      <td>{o.delivery_memo}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className="text-center"
                                    style={{ width: "70px" }}
                                  >
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>

                      <td className="text-center">{o.weight}</td>
                      <td className="text-center">{o.delivery_fee}</td>
                      <td className="text-center">
                        ${genAmount(o.order_product, o.delivery_fee)}
                      </td>
                      <td className="text-center">{o.hawb}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactPaginate
                previousLabel={"이전"}
                nextLabel={"다음"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                pageClassName={"item pagination-page "}
                disabledClassName={"disabled-page"}
              />
            </div>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </NormalLayout>
  );
}

export default Cancel;
