import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faFileAlt,
  faArrowRight,
  faGear,
  faList,
  faFileExcel,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(true);
  const [isHawbMenuOpen, setIsHawbMenuOpen] = useState(true);
  const [isOrderMenuOpen, setIsOrderMenuOpen] = useState(true);
  const [isStockMenuOpen, setIsStockMenuOpen] = useState(true);
  const [isManageMenuOpen, setIsManageMenuOpen] = useState(true);
  const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(true);

  const toggleAdminMenu = () => setIsAdminMenuOpen(!isAdminMenuOpen);

  const toggleHawbMenu = () => setIsHawbMenuOpen(!isHawbMenuOpen);
  const toggleOrderMenu = () => setIsOrderMenuOpen(!isOrderMenuOpen);
  const toggleStockMenu = () => setIsStockMenuOpen(!isStockMenuOpen);
  const toggleManageMenu = () => setIsManageMenuOpen(!isManageMenuOpen);
  const toggleSettingMenu = () => setIsSettingMenuOpen(!isSettingMenuOpen);

  return (
    <div
      className="no-border"
      style={{ height: "100%", minWidth: "170px", maxWidth: "170px" }}
    >
      <br />
      {user && user.groups && user.groups.includes("admin") && (
        <ListGroup className="border  mt-2">
          <ListGroupItem action onClick={toggleAdminMenu} className="no-border">
            <FontAwesomeIcon
              icon={faUserTie}
              style={{ marginRight: "10px", color: "gray" }}
            />
            <span className="fontsize_1rem bold_600">관리자</span>
          </ListGroupItem>
          <Collapse isOpen={isAdminMenuOpen}>
            <ListGroup>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/registered" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  주문등록 완료
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/processing" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  주문 처리 중
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/done" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  발송완료
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/cancel" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  주문취소
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/ledger" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  회계
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/specialprice" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  할인제품
                </Link>
              </ListGroupItem>
              <ListGroupItem action className="no-border fontsize_09rem">
                <Link to="/admin/company" className="no-deco text-dark">
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      color: "gray",
                    }}
                  />
                  도매업체
                </Link>
              </ListGroupItem>
            </ListGroup>
          </Collapse>
        </ListGroup>
      )}

      <ListGroup className="border  mt-2">
        <ListGroupItem action onClick={toggleOrderMenu} className="no-border">
          <FontAwesomeIcon
            icon={faFileExcel}
            style={{ marginRight: "10px", color: "gray" }}
          />
          <span className="fontsize_1rem bold_600">주문서</span>
        </ListGroupItem>
        <Collapse isOpen={isOrderMenuOpen}>
          <ListGroup>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/upload" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                주문하기
              </Link>
            </ListGroupItem>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/list" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                주문목록
              </Link>
            </ListGroupItem>{" "}
            {/* <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/uploadguide" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                업로드 가이드
              </Link>
            </ListGroupItem> */}
          </ListGroup>
        </Collapse>
      </ListGroup>

      <ListGroup className="border  mt-2">
        <ListGroupItem action onClick={toggleManageMenu} className="no-border">
          <FontAwesomeIcon
            icon={faListCheck}
            style={{ marginRight: "10px", color: "gray" }}
          />
          <span className="fontsize_1rem bold_600">주문관리</span>
        </ListGroupItem>
        <Collapse isOpen={isManageMenuOpen}>
          <ListGroup>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/manage/registered" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                주문등록 완료
              </Link>
            </ListGroupItem>

            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/manage/processing" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                주문 처리 중
              </Link>
            </ListGroupItem>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/manage/done" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                발송완료
              </Link>
            </ListGroupItem>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/manage/cancel" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                주문취소
              </Link>
            </ListGroupItem>
          </ListGroup>
        </Collapse>
      </ListGroup>

      <ListGroup className="border  mt-2">
        <ListGroupItem action onClick={toggleStockMenu} className="no-border">
          <FontAwesomeIcon
            icon={faList}
            style={{ marginRight: "10px", color: "gray" }}
          />
          <span className="fontsize_1rem bold_600">제품</span>
        </ListGroupItem>
        <Collapse isOpen={isStockMenuOpen}>
          <ListGroup>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/product" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                제품검색
              </Link>
            </ListGroupItem>
            {/* <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/stock" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                재고현황
              </Link>
            </ListGroupItem> */}
          </ListGroup>
        </Collapse>
      </ListGroup>

      {/* <ListGroup className="border mt-2">
        <ListGroupItem action onClick={toggleSettingMenu} className="no-border">
          <FontAwesomeIcon
            icon={faGear}
            style={{ marginRight: "10px", color: "gray" }}
          />
          <span className="fontsize_1rem bold_600">설정</span>
        </ListGroupItem>
        <Collapse isOpen={isSettingMenuOpen}>
          <ListGroup>
            <ListGroupItem action className="no-border fontsize_09rem">
              <Link to="/setting/airplan" className="no-deco text-dark">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    color: "gray",
                  }}
                />
                항공배송 일정
              </Link>
            </ListGroupItem>
          </ListGroup>
        </Collapse>
      </ListGroup> */}
      <div className="p-5"></div>
    </div>
  );
};

export default Sidebar;
