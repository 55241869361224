import React, { useState, useEffect, useRef, useContext } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import readXlsxFile from "read-excel-file";
import axios from "axios";
import { Table, Button, Row, Col } from "reactstrap";

import Moment from "react-moment";
import sample from "../../sample/special_price_sample.xlsx";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminLedger() {
  return (
    <NormalLayout>
      <div>
        <h3>회계- 개발중</h3>
      </div>
    </NormalLayout>
  );
}

export default AdminLedger;
