import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Moment from "react-moment";
import ReactPaginate from "react-paginate";
// import AdminSearchPageForm from "../../components/search/AdminSearchPageForm";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AdminSearchForm from "../../components/search/AdminSearchForm";
const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminCancel() {
  const [data, setData] = useState(null);

  const [ordersObj, setOrdersObj] = useState({});
  const [wholesalerArr, setWholesalerArr] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState();
  const [currentWholesaler, setCurrentWholesaler] = useState();
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정
  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글
  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  const navigate = useNavigate();

  useEffect(() => {
    excelListData();
  }, [currentPage, pageSize]);

  const excelListData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/orders?status=CANCEL&page=${currentPage}&limit=${pageSize}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
        genSummary(resList.data.data.list);
        setCount(resList.data.data.count.count);
        setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 업체별 주문건 요약
  const genSummary = (orders) => {
    let tempObj = {};

    orders.map((order) => {
      if (tempObj.hasOwnProperty(order.wholesaler)) {
        tempObj[order.wholesaler].push(order);
      } else {
        tempObj[order.wholesaler] = [order];
      }
    });

    const sortedKeys = Object.keys(tempObj).sort();

    // 도매업체 리스트 및 업체별 주문건 설정
    setWholesalerArr(sortedKeys);
    setOrdersObj(tempObj);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items, delivery_fee) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });
    // 배송료 추가
    if (delivery_fee) {
      amount += parseFloat(delivery_fee);
    }

    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    // let resValue = null;
    // let pcode = productCode.toUpperCase();

    // // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    // if (pcode === "BRING" || pcode === "PROMO") {
    //   resValue = "$0";
    // } else if (
    //   pcode === "CHEMIST" ||
    //   pcode === "MART" ||
    //   pcode === "SPECIAL" ||
    //   pcode === ""
    // ) {
    //   resValue = "미정";
    // } else {
    //   resValue =
    //     "$" +
    //     parseFloat(unitPrice * qauntity)
    //       .toFixed(2)
    //       .toString();
    // }
    // return resValue;
    let resValue =
      "$" + (parseFloat(unitPrice) * qauntity).toFixed(2).toString();

    return resValue;
  };

  const selectedOrders = (wholesaler) => {
    setFilteredOrders(ordersObj[wholesaler]);
    setCurrentWholesaler(wholesaler);
  };

  const toggleTooltip = (receiver) => {
    setTooltipOpen({
      ...tooltipOpen,
      [receiver]: !tooltipOpen[receiver],
    });
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <NormalLayout>
      <div>
        <h3>
          주문취소
          {count && <> ({count})건</>}
        </h3>
      </div>
      <div className="p-3"></div>
      {filteredOrders ? (
        <>
          <div className="border p-3">
            <div className="mb-3">
              {/* <div className="p-5  mb-3 border  bg-light">
                <h4>업체별 주문현황</h4>
                <Row className="mt-3">
                  <Col>추가예정</Col>
                </Row>
              </div> */}
              <AdminSearchForm
                target="CANCEL"
                setFilteredOrders={setFilteredOrders}
                token={cookies.get("info").token}
              />
            </div>
            <div className="border p-3">
              <div className="mb-3">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret color="dark">
                    페이지당 노출 수: {pageSize}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => selectPageSize(10)}>
                      10개
                    </DropdownItem>
                    <DropdownItem onClick={() => selectPageSize(30)}>
                      30개
                    </DropdownItem>
                    <DropdownItem onClick={() => selectPageSize(50)}>
                      50개
                    </DropdownItem>
                    <DropdownItem onClick={() => selectPageSize(100)}>
                      100개
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>{" "}
              </div>
              <Table hover bordered striped>
                <thead>
                  <tr className="table-primary">
                    <th className="text-center p-2 border">등록일</th>
                    <th className="text-center p-2 border">출고일</th>
                    <th className="text-center p-2 border">업체명</th>
                    <th className="text-center p-2 border">요청 메모</th>
                    <th className="text-center p-2 border">이름</th>
                    {/* <th className="text-center p-2 border">전화번호</th>
                    <th className="text-center p-2 border">모바일번호</th>
                    <th className="text-center p-2 border">통관부호</th>
                    <th className="text-center p-2 border">우편번호</th>
                    <th className="text-center p-2 border">주소</th> */}
                    <th className="text-center p-2 border">배송메모</th>
                    <th className="text-center p-2 border">주문상품</th>

                    <th className="text-center p-2 border">무게</th>
                    <th className="text-center p-2 border">배송료</th>
                    <th className="text-center p-2 border">총액</th>
                    <th className="text-center p-2 border">운송장번호</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((o, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-center">
                          <Moment format="YYYY-MM-DD">{o.created_at}</Moment>
                        </td>
                        <td className="text-center">
                          <Moment format="YYYY-MM-DD">{o.delivery_at}</Moment>
                        </td>
                        <td className="text-center">{o.wholesaler}</td>
                        <td className="text-center">{o.shipper_memo}</td>
                        <td className="text-center">
                          <span id={`Tooltip-${o.order_index}`}>
                            {o.customer_name}
                          </span>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[o.order_index]}
                            target={`Tooltip-${o.order_index}`}
                            toggle={() => toggleTooltip(o.order_index)}
                          >
                            <div className="text-start">
                              <li>T : {o.customer_tel}</li>
                              <li>M : {o.customer_mobile}</li>
                              <li>통관부호 : {o.pcc}</li>

                              <li>
                                A : {o.address}, #{o.zip}
                              </li>
                            </div>
                          </Tooltip>
                        </td>
                        {/* <td className="text-center">{o.customer_tel}</td>
                        <td className="text-center">{o.customer_mobile}</td>
                        <td className="text-center">{o.pcc}</td>

                        <td className="text-center">{o.zip}</td>
                        <td>{o.address}</td> */}

                        <td>{o.delivery_memo}</td>
                        <td style={{ padding: "0px" }}>
                          <Table
                            bordered
                            style={{ margin: "0px", width: "100%" }}
                          >
                            <tbody>
                              {o.order_product.map((i, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      className="text-center"
                                      style={{ width: "70px" }}
                                    >
                                      {i.productCode}
                                    </td>

                                    <td className="cell">{i.productName}</td>
                                    <td
                                      style={{ width: "60px" }}
                                      className="text-center"
                                    >
                                      {i.qauntity}개
                                    </td>
                                    <td
                                      style={{ width: "70px" }}
                                      className="text-center"
                                    >
                                      {convertPriceFormat(
                                        i.productCode,
                                        i.unitPrice,
                                        i.qauntity
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </td>

                        <td className="text-center">{o.weight}</td>
                        <td className="text-center">{o.delivery_fee}</td>
                        <td className="text-center">
                          ${genAmount(o.order_product, o.delivery_fee)}
                        </td>
                        <td className="text-center">
                          {o.hawb && o.hawb !== "취소" && (
                            <a
                              href={
                                "https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=" +
                                o.hawb
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {o.hawb}
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPaginate
                  previousLabel={"이전"}
                  nextLabel={"다음"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"item active "}
                  breakClassName={"item break-me "}
                  pageClassName={"item pagination-page "}
                  disabledClassName={"disabled-page"}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>주문취소 데이터 없음</>
      )}
    </NormalLayout>
  );
}

export default AdminCancel;
