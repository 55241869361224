import axios from "axios";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

/* 
엑셀 데이터 검증 함수
- 필수필드 유무 확인
- 번호 기준으로 받는사람, 제품 정보가 기입되어 있는지 확인
- 수량 데이터가 숫자 인지 검증
*/

export const isValidExcelData = (rows) => {
  const fieldIndex = setFieldIndex(rows[0]);

  let checkField = [];
  let checkNumber = [];
  let checkNo = [];
  let msg = "";

  // 엑셀파일에 필수 필드가 포함되어 있는 확인
  checkField = findNullKeys(fieldIndex);

  // 통관부호가 있는 라인에 고객정보가 있는지 여부 검증 및 숫자데이터 확인
  rows.map((row, idx) => {
    if (idx !== 0) {
      let no = row[fieldIndex["no"]];
      let pcc = row[fieldIndex["pcc"]];
      let customerName = row[fieldIndex["customerName"]];
      let customerTel = row[fieldIndex["customerTel"]];
      let customerMobile = row[fieldIndex["customerMobile"]];
      let zip = row[fieldIndex["zip"]];
      let address = row[fieldIndex["address"]];
      let memo = row[fieldIndex["memo"]];
      let productCode = row[fieldIndex["item"]["productCode"]];
      let productName = row[fieldIndex["item"]["productName"]];
      let qauntity = row[fieldIndex["item"]["qauntity"]];
      let commerceOrderNo = row[fieldIndex["commerceOrderNo"]];

      // 번호값은 있는데 내부 데이터가 비어있거나 숫자형태여야 되는 수량 값이 숫자 아닌게 포함되어 있는 지 확인
      if (no) {
        if (
          customerName === null ||
          pcc === null ||
          customerTel === null ||
          customerMobile === null ||
          productCode === null ||
          qauntity === null
        ) {
          checkNo.push(idx);
        }

        // 숫자값 여부 파악하기
        if (isNaN(qauntity) || isNaN(no) || qauntity === null || no === null) {
          checkNumber.push(idx);
        }
      } else {
        // 숫자값 여부 파악하기
        if (isNaN(qauntity) || qauntity === null) {
          checkNumber.push(idx);
        }
      }
    }
  });

  // 유효성 결과 리턴
  if (checkField.length > 0 || checkNo.length > 0 || checkNumber.length > 0) {
    if (checkField.length > 0) {
      msg += "- 엑셀파일에 필수 필드가 포함되어 있는지 확인하세요.\n ";
      checkField.map((f, idx) => {
        msg += convertFieldName(f);
        if (checkField.length !== idx + 1) {
          msg += ", ";
        }
      });
    }

    if (checkNo.length > 0) {
      msg += "- 필수정보가 비어있습니다. 내용을 확인하세요. 엑셀 주문서 ";
      checkNo.map((i, idx) => {
        msg += (i + 1).toString();
        if (checkNo.length !== idx + 1) {
          msg += ", ";
        }
      });
      msg += "번째 라인\n";
    }

    if (checkNumber.length > 0) {
      msg += "- 수량, 단가, 무게 필드에 숫자가 맞는지 확인하세요. 엑셀 주문서 ";
      checkNumber.map((i, idx) => {
        msg += (i + 1).toString();
        if (checkNumber.length !== idx + 1) {
          msg += ", ";
        }
      });
      msg += "번째 라인\n";
    }

    return { isValid: false, data: { checkNo, checkNumber, msg } };
  } else {
    return { isValid: true };
  }
};

// null값이 있는 key값 찾는 함수
function findNullKeys(obj, prefix = "") {
  let keysWithNullValue = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (value === null) {
      keysWithNullValue.push(prefixedKey);
    } else if (typeof value === "object" && value !== null) {
      keysWithNullValue = keysWithNullValue.concat(
        findNullKeys(value, prefixedKey)
      );
    }
  });
  return keysWithNullValue;
}

// 필드제목으로 변경하는 함수
function convertFieldName(field) {
  let fieldName = "";

  switch (field) {
    case "no":
      fieldName = "번호";
      break;
    case "wholesalerMemo":
      fieldName = "요청메모";
      break;
    case "customerName":
      fieldName = "이름";
      break;
    case "pcc":
      fieldName = "통관부호";
      break;
    case "customerTel":
      fieldName = "전화번호";
      break;
    case "customerMobile":
      fieldName = "모바일번호";
      break;
    case "zip":
      fieldName = "우편번호";
      break;
    case "address":
      fieldName = "주소";
      break;
    case "memo":
      fieldName = "배송메모";
      break;
    case "item.qauntity":
      fieldName = "수량";
      break;
    case "item.productName":
      fieldName = "영문상품명";
      break;
    case "item.productCode":
      fieldName = "상품코드";
      break;
    case "commerceOrderNo":
      fieldName = "쇼핑몰주문번호";
      break;

    default:
      break;
  }

  return fieldName;
}

// 필드별 인덱스 번호 설정
export const setFieldIndex = (row) => {
  // 필수필드 인덱스
  let fieldIndex = {
    no: null,
    wholesalerMemo: null,
    customerName: null,
    customerTel: null,
    customerMobile: null,
    zip: null,
    address: null,
    pcc: null,
    memo: null,
    item: {
      productCode: null,
      productName: null,
      qauntity: null,
    },
    commerceOrderNo: null,
  };

  row.map((field, idx) => {
    switch (field) {
      case "번호":
        fieldIndex["no"] = idx;
        break;
      case "요청메모":
        fieldIndex["wholesalerMemo"] = idx;
        break;
      case "이름":
        fieldIndex["customerName"] = idx;
        break;
      case "전화번호":
        fieldIndex["customerTel"] = idx;
        break;
      case "모바일번호":
        fieldIndex["customerMobile"] = idx;
        break;
      case "우편번호":
        fieldIndex["zip"] = idx;
        break;
      case "주소":
        fieldIndex["address"] = idx;
        break;
      case "통관번호":
        fieldIndex["pcc"] = idx;
        break;
      case "통관부호":
        fieldIndex["pcc"] = idx;
        break;
      case "배송메모":
        fieldIndex["memo"] = idx;
        break;
      case "상품코드":
        fieldIndex["item"]["productCode"] = idx;
        break;
      case "상품명":
        fieldIndex["item"]["productName"] = idx;
        break;
      case "수량":
        fieldIndex["item"]["qauntity"] = idx;
        break;
      case "쇼핑몰주문번호":
        fieldIndex["commerceOrderNo"] = idx;
        break;
      default:
        break;
    }
  });

  return fieldIndex;
};
