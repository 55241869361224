import * as XLSX from "xlsx";
import { convertYYYYMD } from "./Aboutdate";

let currentDate = convertYYYYMD(new Date());
const currentYYYYMD =
  currentDate.year + "-" + currentDate.month + "-" + currentDate.day;
const currentMD = currentDate.month + "-" + currentDate.day;

export const genExcelDownloadFunc = (orderList) => {
  console.log("orderlist is", orderList);
  let orderArr = [];
  orderList.map((order, idx) => {
    order.order_product.map((item, idx2) => {
      // orderArr.push([
      //   idx2 === 0 ? idx + 1 : "",
      //   idx2 === 0 ? `${order.wholesaler}` : "",
      //   idx2 === 0 ? `${currentYYYYMD}` : "",
      //   idx2 === 0 ? `${currentMD}` : "",
      //   idx2 === 0 ? "-" : "",
      //   "",
      //   idx2 === 0 ? (order.hawb === null ? "" : `${order.hawb}`) : "",
      //   order.wholesaler,
      //   idx2 === 0 ? `${order.order_no}` : "",
      //   idx2 === 0 ? `${order.shipper_memo}` : "",
      //   idx2 === 0 ? `${order.customer_name}` : "",
      //   idx2 === 0 ? `${order.pcc}` : "",
      //   idx2 === 0 ? `${order.customer_tel}` : "",
      //   idx2 === 0 ? `${order.customer_mobile}` : "",
      //   idx2 === 0 ? `${order.zip}` : "",
      //   idx2 === 0 ? `${order.address}` : "",
      //   idx2 === 0 ? `${order.delivery_memo}` : "",
      //   item.productCode,
      //   item.productName,
      //   item.qauntity,
      //   item.weight,
      //   "",
      //   !isNaN(item.unitPrice) && item.unitPrice * item.qauntity,
      //   idx2 === 0
      //     ? order.delivery_fee === null
      //       ? ""
      //       : `${order.delivery_fee}`
      //     : "",
      //   "",
      //   "",
      //   "",
      //   item.productName,
      //   item.unitPrice,
      //   idx2 === 0 ? `${order.wholesaler_order_no}` : "",
      //   "",
      //   "",
      // ]);
      orderArr.push([
        idx2 === 0 ? idx + 1 : null,
        idx2 === 0 ? `${order.wholesaler}` : null,
        idx2 === 0 ? `${currentYYYYMD}` : null,
        idx2 === 0 ? `${currentMD}` : null,
        idx2 === 0 ? "-" : null,
        null,
        idx2 === 0 ? (order.hawb === null ? null : `${order.hawb}`) : null,
        order.wholesaler,
        idx2 === 0 ? `${order.order_no}` : null,
        idx2 === 0
          ? order.shipper_memo === null
            ? null
            : `${order.shipper_memo}`
          : null,
        idx2 === 0 ? `${order.customer_name}` : null,
        idx2 === 0 ? `${order.pcc}` : null,
        idx2 === 0 ? `${order.customer_tel}` : null,
        idx2 === 0 ? `${order.customer_mobile}` : null,
        idx2 === 0 ? `${order.zip}` : null,
        idx2 === 0 ? `${order.address}` : null,
        idx2 === 0
          ? order.delivery_memo === null
            ? null
            : `${order.delivery_memo}`
          : null,
        item.productCode,
        item.productName,
        item.qauntity,
        item.weight,
        null,
        !isNaN(item.unitPrice) && item.unitPrice * item.qauntity,
        idx2 === 0
          ? order.delivery_fee === null
            ? null
            : `${order.delivery_fee}`
          : null,
        null,
        null,
        null,
        item.productName,
        item.unitPrice,
        idx2 === 0 ? `${order.wholesaler_order_no}` : null,
        null,
        null,
      ]);
    });
  });

  const ws = XLSX.utils.aoa_to_sheet([
    [
      "번호",
      "회사코드",
      "배송일",
      "발주일",
      "보낸이",
      "추가서류",
      "HAWB",
      "마켓코드",
      "주문번호",
      "확인",
      "이름",
      "통관부호",
      "전화번호",
      "모바일번호",
      "우편번호",
      "주소",
      "배송메모",
      "재고관리코드",
      "기초상품명",
      "수량",
      "무게",
      "사은품",
      "금액",
      "배송비",
      "온라인상품명",
      "옵션",
      "HSCODE",
      "영문상품명",
      "단가",
      "쇼핑몰주문번호",
      "결제완료일",
      "주문수집일",
    ],
    ...orderArr.map((order) => order), // 두 번째 행부터 데이터
  ]);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "uploadform");

  // 파일 생성 및 내보내기
  let downloadDate = new Date();
  XLSX.writeFile(wb, `주문등록목록_${downloadDate}.xlsx`);
};

// export const genExcelDownloadFunc = (orderList) => {
//   console.log("check orderlist", orderList);
//   let orderArr = [];
//   orderList.map((order, idx) => {
//     order.order_product.map((item, idx2) => {
//       orderArr.push([
//         idx2 === 0 ? `${order.order_no}` : "",
//         idx2 === 0 ? `${order.hawb}` : "",
//         idx2 === 0 ? `${order.wholesaler}` : "",
//         idx2 === 0 ? "보내는 사람 전화번호" : "",
//         idx2 === 0 ? "보내는 사람 주소" : "",
//         `MEGA-${order.order_index}`,
//         "",
//         "NZAKL",
//         order.customer_name,
//         order.customer_tel,
//         order.customer_mobile,
//         order.zip,
//         order.address,
//         "",
//         `${order.pcc}`,
//         order.delivery_memo,
//         "1",
//         "1",
//         "www.megamall.nz",
//         0,
//         0,
//         0,
//         0,
//         "1",
//         idx2 === 0 ? "1" : "0",
//         "0",
//         "",
//         "",
//         item.productName,
//         item.productName,
//         item.unitPrice,
//         item.qauntity,
//         "HSCODE",
//         "",
//         "",
//         idx2 === 0 ? `${order.order_index}` : "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "",
//         "megamall",
//         "",
//         "",
//         "",
//         "",
//         "",
//       ]);
//     });
//     orderArr.push([]);
//   });

//   const ws = XLSX.utils.aoa_to_sheet([
//     [
//       "번호",
//       "HAWB",
//       "보내는 사람",
//       null,
//       null,
//       "고객번호",
//       "박스코드",
//       "POL",
//       "고객정보",
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       "일반/무게",
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       "아이템 목록(영문)",
//       null,
//       null,
//       null,
//       null,
//       "Freight Term",
//       "Customer Order No",
//       "우체국송장번호",
//       "로컬언어상품명",
//       "통화코드",
//       "해외Sales Tax",
//       "해외 내 Delivery Fee",
//       "제품정보",
//       null,
//       null,
//       "구매대행사",
//       null,
//       null,
//       null,
//       null,
//       null,
//       "로컬배송",
//       "Mall ID",
//       "Mall Item",
//       "제품URL",
//       "성분",
//       "길이단위",
//       "실화주ID",
//       "Carr CD",
//       "신용/착불",
//       "반품여부Y/N",
//       "전자상거래유형코드",
//       "해외판매자부호",
//       "해외판매자상호",
//       "구매/배송 대행업자부호",
//       "구매/배송 대행업자상호",
//       "중개업자부호",
//       "중개업자상호",
//       "주문번호",
//     ], // 첫 번째 행, '운송장 번호'를 네 번째 열에 포함
//     [
//       null,
//       null,
//       "이름",
//       "전화",
//       "주소",
//       null,
//       null,
//       null,
//       "이름(한글)",
//       "전화",
//       "휴대폰",
//       "우편번호",
//       "주소",
//       "상세주소",
//       "통관부호",
//       "배송메모",
//       "받는이 구분",
//       "전자상거래",
//       "홈페이지 주소",
//       "가로(Cm)",
//       "세로(cm)",
//       "높이(cm)",
//       "중량",
//       "중량단위",
//       "Box수량",
//       "일반신청",
//       "통관지정번호",
//       "Gmarket체결번호",
//       "상품명",
//       "브랜드",
//       "단가",
//       "수량",
//       "목록허용품목코드",
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       "식품종류",
//       "제조사",
//       "제조국가코드",
//       "영업등록번호",
//       "상호",
//       "대표자",
//       "연락처",
//       "주소",
//       "쇼핑몰주소",
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//     ], // 두 번째 행
//     ...orderArr.map((order) => order), // 세 번째 행부터 데이터
//   ]);

//   // 첫 번째 행의 첫 3개 열을 병합하고, '운송장 번호' 열을 병합,
//   if (!ws["!merges"]) ws["!merges"] = [];
//   ws["!merges"].push({ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }); // '번호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }); // 'HAWB'에 대한 행병합

//   ws["!merges"].push({ s: { r: 0, c: 2 }, e: { r: 0, c: 4 } }); // '보내는 사람'에 대한 열병합
//   ws["!merges"].push({ s: { r: 0, c: 5 }, e: { r: 1, c: 5 } }); // '고객 번호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 6 }, e: { r: 1, c: 6 } }); // '박스코드'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 7 }, e: { r: 1, c: 7 } }); // 'POL'에 대한 행병합

//   ws["!merges"].push({ s: { r: 0, c: 8 }, e: { r: 0, c: 16 } }); // '고객정보'에 대한 열병합
//   ws["!merges"].push({ s: { r: 0, c: 17 }, e: { r: 0, c: 27 } }); // '일반/무게'에 대한 열병합
//   ws["!merges"].push({ s: { r: 0, c: 28 }, e: { r: 0, c: 32 } }); // '아이템목록'에 대한 열병합

//   ws["!merges"].push({ s: { r: 0, c: 33 }, e: { r: 1, c: 33 } }); // 'Freight Term'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 34 }, e: { r: 1, c: 34 } }); // 'Customer Order No'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 35 }, e: { r: 1, c: 35 } }); // '우체국 송장번호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 36 }, e: { r: 1, c: 36 } }); // '로컬언어상품명'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 37 }, e: { r: 1, c: 37 } }); // '통화코드'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 38 }, e: { r: 1, c: 38 } }); // '해외sales tax'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 39 }, e: { r: 1, c: 39 } }); // '해외 내 delivery fee'에 대한 행병합

//   ws["!merges"].push({ s: { r: 0, c: 40 }, e: { r: 0, c: 42 } }); // '제품정보'에 대한 열병합
//   ws["!merges"].push({ s: { r: 0, c: 43 }, e: { r: 0, c: 48 } }); // '구매대행사'에 대한 열병합

//   ws["!merges"].push({ s: { r: 0, c: 49 }, e: { r: 1, c: 49 } }); // '로컬배송'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 50 }, e: { r: 1, c: 50 } }); // 'Mall ID'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 51 }, e: { r: 1, c: 51 } }); // 'Mall Item'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 52 }, e: { r: 1, c: 52 } }); // '제조 URL'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 53 }, e: { r: 1, c: 53 } }); // '성분'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 54 }, e: { r: 1, c: 54 } }); // '길이단위'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 55 }, e: { r: 1, c: 55 } }); // '실화주 ID'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 56 }, e: { r: 1, c: 56 } }); // 'CARR CD'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 57 }, e: { r: 1, c: 57 } }); // '신용/착불'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 58 }, e: { r: 1, c: 58 } }); // '반품여부'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 59 }, e: { r: 1, c: 59 } }); // '전자상거래유형코드'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 60 }, e: { r: 1, c: 60 } }); // '해외판매자부호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 61 }, e: { r: 1, c: 61 } }); // '해외판매자상호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 62 }, e: { r: 1, c: 62 } }); // '구매/배송 대행업자부호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 63 }, e: { r: 1, c: 63 } }); // '구매/배송 대행업자상호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 64 }, e: { r: 1, c: 64 } }); // '중개업자부호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 65 }, e: { r: 1, c: 65 } }); // '중개업자상호'에 대한 행병합
//   ws["!merges"].push({ s: { r: 0, c: 66 }, e: { r: 1, c: 66 } }); // '주문번호'에 대한 행병합

//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "uploadform");

//   // 파일 생성 및 내보내기
//   let downloadDate = new Date();
//   XLSX.writeFile(wb, `주문등록목록_${downloadDate}.xlsx`);
// };
