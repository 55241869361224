import React from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function UploadGuide() {
  return (
    <NormalLayout>
      <div>
        <h3>주문 가이드</h3>
      </div>
    </NormalLayout>
  );
}

export default UploadGuide;
