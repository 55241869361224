import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

export default function NormalLayout({ children }) {
  const { user, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isLoading) {
    if (!user) {
      navigate("/login", { replace: true });
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header />

      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        {/* Sidebar */}
        <div
          style={{ width: "180px", backgroundColor: "#f8f9fa", height: "100%" }}
        >
          <Sidebar />
        </div>
        {/* Main Content */}
        <div style={{ flex: 1, padding: "20px" }}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
