export const convertYYYYMD = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear(); // 년도
  const month = date.getMonth() + 1; // 월 (0부터 시작하므로 1을 더해줍니다)
  const day = date.getDate(); // 일

  // 결과를 "YYYY-MM-DD" 형식의 문자열로 반환
  return {
    year,
    month,
    day,
  };
};
