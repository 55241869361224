import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import "./App.css";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
// import ConfirmSignUp from "./pages/ConfirmSignUp";
import ErrorPage from "./pages/ErrorPage";

// 주문
import Upload from "./pages/order/Upload";
import UploadList from "./pages/order/UploadList";
import UploadGuide from "./pages/order/UploadGuide";

// 주문관리
import Registered from "./pages/manage/Registered";
import Processing from "./pages/manage/Processing";
import Done from "./pages/manage/Done";
import Cancel from "./pages/manage/Cancel";

// 제품
import Product from "./pages/product/Product";

// 관리자
import AdminRegistered from "./pages/admin/AdminRegistered";
import AdminProcessing from "./pages/admin/AdminProcessing";
import AdminDone from "./pages/admin/AdminDone";
import AdminCancel from "./pages/admin/AdminCancel";
import AdminLedger from "./pages/admin/AdminLedger";
import AdminSpecialPrice from "./pages/admin/AdminSpecialPrice";
import AdminCompany from "./pages/admin/AdminCompany";

// 메인
import Main from "./pages/Main";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          {/* <Route path="/confirm" element={<ConfirmSignUp />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:email" element={<ResetPassword />} />

          {/* 주문 */}
          <Route path="/list" element={<UploadList />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/uploadguide" element={<UploadGuide />} />

          {/* 주문관리 */}
          <Route path="/manage/registered" element={<Registered />} />
          <Route path="/manage/processing" element={<Processing />} />
          <Route path="/manage/done" element={<Done />} />
          <Route path="/manage/cancel" element={<Cancel />} />

          {/* 제품 */}
          <Route path="/product" element={<Product />} />

          {/* 관리자 */}
          <Route path="/admin/registered" element={<AdminRegistered />} />
          <Route path="/admin/processing" element={<AdminProcessing />} />
          <Route path="/admin/done" element={<AdminDone />} />
          <Route path="/admin/cancel" element={<AdminCancel />} />
          <Route path="/admin/ledger" element={<AdminLedger />} />
          <Route path="/admin/specialprice" element={<AdminSpecialPrice />} />
          <Route path="/admin/company" element={<AdminCompany />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
