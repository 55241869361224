export const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const insertDashedInHBL = (str) => {
  let dd = str.toString();
  // 5번째 자리에 '-'추가
  let result = dd.slice(0, 4) + "-" + dd.slice(4);
  result = result.slice(0, 9) + "-" + result.slice(9);

  return result;
};
