import React, { useEffect, useRef, useState, useContext } from "react";
import { Container, Table, Spinner, Button, Alert, Row, Col } from "reactstrap";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import { Link, useNavigate } from "react-router-dom";
import NormalLayout from "../../components/layout/NormalLayout";
import { AuthContext } from "../../context/AuthContext";
import { Cookies } from "react-cookie";
import { isValidExcelData, setFieldIndex } from "../../utils/CheckValidData";
import excelSample from "../../sample/upload_excel_sample.xlsx";
import { faHouseChimneyCrack } from "@fortawesome/free-solid-svg-icons";

const cookies = new Cookies();

function Upload() {
  const { user } = useContext(AuthContext);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [isLoadingUplaod, setIsLoadingUpload] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const [deliveryCodes, setDeliveryCodes] = useState([]);
  const [isLoadingDeliveryCodes, setIsLoadingDeliveryCodes] = useState(false);

  const [isShowUploadForm, setIsShowUploadForm] = useState(true);

  const [products, setProducts] = useState([]);
  const [ordersValid, setOrdersValid] = useState(true);

  const [checkProductNameOrderArr, setCheckProductNameOrderArr] = useState([]);

  const readUploadFile = async (e) => {
    // 초기화
    setOrderList([]);
    setIsLoadingUpload(true);
    setIsShowUploadForm(false);
    let isValidFile = true;

    // 1단계 : 엑셀데이터 rows에 담기
    const rows = await readXlsxFile(e.target.files[0])
      .then((resRows) => {
        return resRows;
      })
      .catch((err) => {
        console.log("err is", err);
      });

    fileInputRef.current.value = "";

    // 2단계 : 데이터 유효성 검사
    if (!rows) {
      alert("주문서 파일을 읽을 수 없습니다.");
      setIsLoadingUpload(false);
      return;
    }

    let resIsValidExcelData = isValidExcelData(rows);

    //   유효성 검사에 문제가 있는 경우 재 업로드 유도
    if (!resIsValidExcelData.isValid) {
      setIsLoadingUpload(false);
      isValidFile = false;
      alert(resIsValidExcelData.data.msg);
      return;
    }

    let tempOrderList = [];
    let tempOrdersObj = {};
    let tempNo = 0;
    let tempNoArr = [];
    let tempProductArr = [];
    let tempItems = [];
    let tempReceiverGroup = [];
    let tempAddressGroup = [];
    const fieldIndex = setFieldIndex(rows[0]); //필드별 인덱스 설정

    // 데이터 검증 후 row 단위로 데이터 저장하기
    rows.map((row, idx) => {
      try {
        // 엑셀파일 2번째 행부터 각각의 필요한 필드 저장하기
        if (idx !== 0) {
          let no = row[fieldIndex["no"]];
          let productCode = row[fieldIndex["item"]["productCode"]];

          // 제품 코스 수집

          if (
            productCode !== "BRING" &&
            productCode !== "PROMO" &&
            productCode !== "CHEMIST" &&
            productCode !== "MART" &&
            productCode !== "SPECIAL" &&
            productCode !== ""
          ) {
            tempProductArr.push(productCode);
          }

          // 주문된 제품 내용
          let item = {
            productCode,
            productName: row[fieldIndex["item"]["productName"]],
            qauntity: row[fieldIndex["item"]["qauntity"]],
          };

          // No 값이 있는 경우 object를 만들고 주문정보를 추가하고, 값이 없는 경우 이미 만들어진 key에 아이템만 추가한다.
          if (no) {
            // 번호가 있는 행
            tempItems = []; //임시 items 초기화
            tempNo = no; //임시 No 설정

            tempItems.push(item);
            tempNoArr.push(no);

            let wholesalerMemo = row[fieldIndex["wholesalerMemo"]];
            let customerName = row[fieldIndex["customerName"]];
            let pcc = row[fieldIndex["pcc"]];
            let customerTel = row[fieldIndex["customerTel"]].replaceAll(
              "~",
              "-"
            );
            let customerMobile = row[fieldIndex["customerMobile"]].replaceAll(
              "~",
              "-"
            );
            let zipCode = row[fieldIndex["zip"]].toString();
            if (zipCode.length === 4) {
              zipCode = "0" + zipCode;
            }
            let address = row[fieldIndex["address"]];
            let deliveryMemo = row[fieldIndex["memo"]];
            let commerceOrderNo = row[fieldIndex["commerceOrderNo"]];

            // 주문정보
            tempOrdersObj[no] = {
              no,
              wholesalerMemo,
              customerName,
              customerTel,
              customerMobile,
              pcc,
              zipCode,
              address,
              deliveryMemo,
              items: tempItems,
              commerceOrderNo,
            };

            // 통관부호 유효성 확인을 위한 정보
            tempReceiverGroup.push({
              hawb: no,
              customerName,
              customerTel,
              customerMobile,
              pcc,
            });

            // 집배코드를 가져오기 위한 정보
            tempAddressGroup.push({
              id: no,
              zip: zipCode,
              address,
            });
          } else {
            // 번호가 없는 행
            tempItems.push(item);
            tempOrdersObj[tempNo]["items"] = tempItems;
          }
        }
      } catch (err) {
        console.log(err);
      }
    });

    // 제품코드별 제품정보 가져오기(코드, 영문명, 가격)
    let uniqueProductCode = [...new Set(tempProductArr)];

    // 제품정보 가져오기
    let tempProducts = null;
    await axios
      .post(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/wholesale/search/price`,
        {
          grade: user && user.zoneinfo && user.zoneinfo,
          productCodes: uniqueProductCode,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      )
      .then((data) => {
        if (data.data.res === "success") {
          tempProducts = data.data.data;
          setProducts(tempProducts);
        } else {
          // alert("제품정보가 없음, 작성하신 제품코드를 확인해 주세요.");
          // return;
        }
      })
      .catch((Error) => {
        console.log(Error);
      });

    // 파일에 정보가 정상인 경우 다음 단계 진행
    if (isValidFile) {
      // 집배코드 가져오기
      const getDeliveryCode = await axios
        .post(`${process.env.REACT_APP_UTILS_API_URL}/gendeliverycode`, {
          addressgroup: tempAddressGroup,
        })
        .then((data) => {
          setIsLoadingDeliveryCodes(false);
          return data.data;
        })
        .catch((Error) => {
          console.log(Error);
        });
      setDeliveryCodes(getDeliveryCode.resultArr);

      // 집배코드 분석 만약, 집배코드를 못가져오는 주소가 있다면 주문불가처리
      getDeliveryCode.resultArr.map((d) => {
        if (d.res === "fail") {
          setOrdersValid(false);
        }
      });

      // 통관부호 유효성 검사
      const checkPccValid = await axios
        .post(`${process.env.REACT_APP_UTILS_API_URL}/checkpcc/agg4`, {
          receivers: tempReceiverGroup,
        })
        .then((data) => {
          return data.data;
        })
        .catch((Error) => {
          console.log(Error);
        });

      // DB에 저장할 주문데이터 생성하기. 운송장번호 기준으로 저장된 주문아이템과 집배코드를 합친 형태의 데이터
      let tempCheckProductNameOrderArr = [];

      tempOrderList = tempNoArr.map((no) => {
        // 제품명 확인 : 상품코드에 적합한 상품명 확인, 서로 다른경우 제품코드 배열에 추가하기 checkProductNameOrderArr
        tempOrdersObj[no].items.map((i) => {
          if (tempProducts) {
            tempProducts.hasOwnProperty(i.productCode) &&
              tempProducts[i.productCode].name.trim() !==
                i.productName.trim() &&
              tempCheckProductNameOrderArr.push({
                no,
                customer: tempOrdersObj[no].customerName,
                productName: tempProducts[i.productCode].name,
                wrongProductName: i.productName,
              });
          }
        });

        return {
          ...tempOrdersObj[no],
          deliveryCode: getDeliveryCode.resultObj[no],
          pccValid: checkPccValid.resultObj[no],
        };
      });

      setCheckProductNameOrderArr(tempCheckProductNameOrderArr);
      setOrderList(tempOrderList);

      setIsLoadingUpload(false);
    }
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.productName,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });

    return amount;
  };

  const convertPriceFormat = (productCode, productName, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      // 제품키가 있는지 확인하고 있으면 가격정도 리턴, 없으면 코드없음으로 리턴
      if (products.hasOwnProperty(pcode) === true) {
        resValue =
          "$" +
          parseFloat(products[pcode]["price"] * qauntity)
            .toFixed(2)
            .toString();
      } else {
        resValue = "코드 없음";
      }
    }

    return resValue;
  };

  const handleCancel = () => {
    setOrderList([]);
    setIsShowUploadForm(true);
    setOrdersValid(true);
  };

  const handleSave = async () => {
    // 집배코드가 정확한 경우 등록하고, 그렇지 않은 경우 Alert으로 안내
    if (ordersValid) {
      // 저장할 때, 각 주문제품마다 단가정보 추가하기
      let tempOrders = orderList;
      let finalOrders = [];

      tempOrders.map((order) => {
        let tempOrder = order;
        let tempItemsArr = [];
        order.items.map((item) => {
          let tempItem = item;
          // 상품별 가격정보 넣기
          tempItem["unitPrice"] =
            products.hasOwnProperty(item.productCode) === true
              ? products[item.productCode].price
              : 0;
          // 상품코드에 맞는 상품명 넣기
          tempItem["productName"] = products.hasOwnProperty(item.productCode)
            ? products[item.productCode].name
            : tempItem["productName"];

          tempItemsArr.push(tempItem);
        });
        tempOrder["items"] = tempItemsArr;

        finalOrders.push(tempOrder);
      });

      // 통관부호 유효성 검사
      await axios
        .post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/wholesale`,
          {
            orders: finalOrders,
            email: user.email,
            wholesaler: user.profile,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        )
        .then((data) => {
          if (data.data.result === "success") {
            navigate("/manage/registered");
          }
        })
        .catch((Error) => {
          console.log(Error);
        });
    } else {
      alert("주소와 우편번호를 확인 후 다시 등록해주세요. ");
    }
  };

  return (
    <NormalLayout>
      <div>
        <h3>주문하기</h3>
        <li className="text-danger mt-3">
          엑셀 필수 필드 : 번호, 이름, 전화번호, 모바일번호, 우편번호, 주소,
          통관부호, 배송메모, 상품코드, 상품명, 수량, 쇼핑몰주문번호
        </li>
        <li className="text-danger">
          상품명은 상품코드를 기준으로 결정됩니다. 상품코드와 상품명이 다른 경우
          확인 후 다시 올려주세요.
        </li>
        <li>
          <a href={excelSample} target="_blank" rel="noreferrer">
            샘플 파일 다운로드
          </a>
        </li>
      </div>
      <div className="mt-3" style={{ display: isShowUploadForm && "hidden" }}>
        <form className="p-5 border">
          <label htmlFor="upload">엑셀파일 업로드</label>
          <input
            type="file"
            name="upload"
            id="upload"
            ref={fileInputRef}
            onChange={readUploadFile}
          />
        </form>
      </div>
      {/* {!isShowUploadForm && (
        <Alert>
          [주문목록 확인 포인트]

          <li>
            집배코드 : 집배코드가 없는 경우는 주소와 우편번호를 확인해주세요.
          </li>
        </Alert>
      )} */}

      {isLoadingUplaod && (
        <>
          <p>통관번호 확인 및 집배코드 가져오는 중</p>
          <Spinner className="m-5" color="primary">
            Loading...
          </Spinner>
        </>
      )}

      {orderList && orderList.length > 0 && (
        <>
          <h4 className="mt-5">주문목록 ({orderList.length}건)</h4>
          {checkProductNameOrderArr && checkProductNameOrderArr.length > 0 && (
            <Alert color="danger">
              [상품코드와 상품명이 다른 주문건 확인]
              {checkProductNameOrderArr.map((p, idx) => {
                return (
                  <li key={idx}>
                    번호 : {p.no}({p.customer}),{" "}
                    <span className="text-danger">
                      상품명(수정) : {p.productName}
                    </span>
                    ,{" "}
                    <span className="text-info">
                      상품명(원본) : {p.wrongProductName}
                    </span>
                  </li>
                );
              })}
            </Alert>
          )}
          <Table hover bordered className="mt-3">
            <thead className="fontsize_14px">
              <tr className="table-primary">
                <th className="text-center p-2 border">번호</th>
                <th className="text-center p-2 border">요청메모</th>
                <th className="text-center p-2 border">이름</th>
                <th className="text-center p-2 border">전화번호</th>
                <th className="text-center p-2 border">모바일번호</th>
                <th className="text-center p-2 border">통관부호</th>
                {/* <th className="text-center p-2 border">통관부호 확인</th> */}
                <th className="text-center p-2 border">우편번호</th>
                <th className="text-center p-2 border">주소</th>
                {/* <th className="text-center p-2 border">집배코드</th> */}
                <th className="text-center p-2 border">배송메모</th>
                <th className="text-center p-2 border">주문상품</th>
                <th className="text-center p-2 border">
                  예상금액
                  <br />
                  (배송비 제외)
                </th>
                <th className="text-center p-2 border">쇼핑몰주문번호</th>
              </tr>
            </thead>
            <tbody className="fontsize_12px">
              {orderList.map((o, idx) => {
                return (
                  <tr key={idx}>
                    <td className="text-center">{o.no}</td>
                    <td className="text-center">{o.wholesalerMemo}</td>
                    <td className="text-center">{o.customerName}</td>
                    <td className="text-center">{o.customerTel}</td>
                    <td className="text-center">{o.customerMobile}</td>
                    <td className="text-center">{o.pcc}</td>
                    {/* <td
                      className="text-center"
                      style={{
                        fontWeight: "600",
                        background: o.pccValid.isValid === false && "#FA5858",
                      }}
                    >
                      {o.pccValid.isValid === false ? (
                        o.pccValid.data.reason
                      ) : (
                        <>정상 </>
                      )}
                    </td> */}
                    <td className="text-center">{o.zipCode}</td>
                    <td>{o.address}</td>
                    {/* <td
                      style={{
                        background: o.deliveryCode.res === "fail" && "#FA5858",
                      }}
                    >
                      {o.deliveryCode.res === "fail" ? (
                        "주소와 우편번호 확인필요"
                      ) : (
                        <>
                          {" "}
                          {o.deliveryCode.deliveryPoint}{" "}
                          {o.deliveryCode.centerPoint}{" "}
                          {o.deliveryCode.deliveryAreaCd}{" "}
                          {o.deliveryCode.courseNo}{" "}
                        </>
                      )}
                    </td> */}

                    <td>{o.deliveryMemo}</td>
                    <td style={{ padding: "0px" }}>
                      <Table bordered style={{ margin: "0px", width: "100%" }}>
                        <tbody>
                          {o.items.map((i, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  className="text-center"
                                  style={{ width: "70px" }}
                                >
                                  {i.productCode}
                                </td>

                                <td className="cell">
                                  {products.hasOwnProperty(i.productCode) &&
                                  products[i.productCode].name.trim() !==
                                    i.productName.trim() ? (
                                    <span className="text-danger">
                                      {products[i.productCode].name}
                                    </span>
                                  ) : (
                                    <span>{i.productName}</span>
                                  )}
                                </td>
                                <td
                                  style={{ width: "60px" }}
                                  className="text-center"
                                >
                                  {i.qauntity}개
                                </td>
                                <td
                                  style={{ width: "70px" }}
                                  className="text-center"
                                >
                                  {convertPriceFormat(
                                    i.productCode,
                                    i.productName,
                                    i.qauntity
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </td>
                    <td className="text-center">
                      ${genAmount(o.items).toFixed(2)}
                    </td>
                    <td className="text-center">{o.commerceOrderNo}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}

      {orderList.length > 0 && (
        <>
          <Container>
            {" "}
            <Row>
              <Col>
                <div className="p-3 text-center">
                  <span
                    style={{ fontSize: "2rem", textDecoration: "underline" }}
                  >
                    총 주문 건수 : {orderList.length}건
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={() => handleCancel()}
                  className="mt-3"
                  color="secondary"
                  size="lg"
                  block
                >
                  취소
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => handleSave()}
                  className="mt-3"
                  color="primary"
                  size="lg"
                  block
                >
                  주문서 등록
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      )}
      <div className="p-5"></div>
    </NormalLayout>
  );
}

export default Upload;
