import { useState } from "react";
import { signUp, confirmSignUp } from "../components/auth/auth";
import {
  Container,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signUp(email, password);
      navigate("/confirm");
      // setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirmSignUp(username, code);
      alert("확인 완료");
      setConfirmSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div>
        <h2>회원가입 성공</h2>
        <p>이메일에서 승인 코드를 확인하세요.</p>
        <div>
          <h2>회원가입 확인</h2>
          <form onSubmit={handleConfirmSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="text"
              placeholder="Confirmation code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button type="submit">확인</button>
          </form>
          {error && <p>{error}</p>}
        </div>
      </div>
    );
  }

  if (confirmSuccess) {
    return (
      <div>
        <h2>회원가입 성공</h2>
        <p>Kia Ora 로그인 후 이용하시기 바랍니다.</p>
        <Link to="/login">로그인</Link>
      </div>
    );
  }

  return (
    <Container className="login-container">
      <div className="p-5 square border border-primary rounded">
        <h2>회원가입</h2>
        {error && <Alert className="mt-3">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <Button type="submit" block color="primary">
            회원가입
          </Button>
        </Form>
        {error && <p>{error}</p>}
      </div>
    </Container>
  );
}
