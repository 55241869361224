import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  InputGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { orderStatus } from "../../utils/ConvertWord";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../../context/AuthContext";
import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../utils/AboutNumber";

const cookies = new Cookies();

function Product() {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [g, setG] = useState("");

  useEffect(() => {
    setG(cookies.get("info").g);
    const getInfo = async () => {
      let info = await axios
        .get(`${process.env.REACT_APP_WHOLESALE_API_URL}/search/default`, {
          headers: {
            Authorization: cookies.get("info").token,
          },
        })
        .then((data) => {
          if (data.data.res === "success") {
            setProducts(data.data.data);
          }
        })
        .catch((Error) => {
          console.log(Error);
        });
    };

    getInfo();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleShow = (brand) => {
    let resArr = [];

    products.map((product, idx) => {
      if (product.en_name.toLowerCase().match(brand.toLowerCase())) {
        resArr.push(product);
      }
    });

    return (
      <>
        <div
          className="bg-light rounded"
          style={{
            width: "100%",
            borderRadius: "5px",
          }}
        >
          <Table
            striped
            bordered
            hover
            className="text-dark bg-light"
            style={{ width: "100%", fontSize: "1.2rem" }}
          >
            <thead>
              <tr className="table-primary">
                <th className="text-center">No</th>
                <th className="text-center">제품코드</th>
                <th className="text-center">제품명</th>
                <th className="text-center">공급가</th>
                <th className="text-center">소매판매가(1개)</th>
                <th className="text-center">2개</th>
                <th className="text-center">3개</th>
                <th className="text-center">4개</th>
                <th className="text-center">5개</th>
                <th className="text-center">6개 이상</th>
                <th className="text-center">특이사항</th>
                <th className="text-center">재고상태</th>
              </tr>
            </thead>
            <tbody>
              {resArr.length === 0 && (
                <tr className="border">
                  <td colSpan="12" className="p-5">
                    등록된 제품 없음
                  </td>
                </tr>
              )}

              {resArr.map((p, idx) => {
                return (
                  <tr key={p.product_code}>
                    <td className="text-center">{idx + 1}</td>
                    <td className="text-center">{p.product_code}</td>
                    <td style={{ textAlign: "left" }}>{p.en_name}</td>
                    <td className="text-center">{p.price}</td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over1)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over2)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over3)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over4)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over5)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(p.retail_price_over6)}
                    </td>
                    <td className="text-center">
                      {p.product_status === "판매중" ? "" : p.product_status}{" "}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        backgroundColor:
                          p.stock_status === "empty"
                            ? "lightgrey"
                            : p.stock_status,
                      }}
                    >
                      {p.stock_status === "empty" && "0"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (keyword === "") {
      alert("검색어를 입력해 주세요. ");
      return;
    }
    setData([]);

    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/search/product?keyword=${keyword}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.status === 200) {
        setData(resList.data);
        console.log(resList.data);
        if (resList.data.length === 0) {
          alert(
            `입력하신 '${keyword}'에 대한 제품은 메가몰 내 검색결과가 없습니다.`
          );
        }
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleKeyPressSearch = (e) => {
    if (e.charCode === 13) {
      handleSearch();
    }
  };
  return (
    <NormalLayout>
      <Row className="my-4">
        <Col>
          <h3 className="">제품검색</h3>{" "}
        </Col>
      </Row>

      {(g === "kor" || g === "korg") && (
        <div className="my-4 p-3 border rounded bg-light">
          <Row>
            <Col>
              <Form onSubmit={handleSearch}>
                <FormGroup>
                  <Label for="keyword">
                    제품코드 또는 제품명으로 검색하세요.
                  </Label>
                  <InputGroup
                    className="text-center"
                    style={{ width: "500px" }}
                  >
                    <Input
                      type="text"
                      id="keyword"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      placeholder="검색어를 입력해주세요."
                    />
                    <Button type="submit" color="primary">
                      검색
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </div>
      )}
      <Alert className="text-start" style={{ width: "100%", fontSize: "1rem" }}>
        <div></div>

        <div className="mt-3">
          <h6>[재고 정보 안내]</h6>
          <li>
            재고상태 : '재고상태'열의 배경색에 따라 재고상태를 알수 있습니다.{" "}
            <span style={{ color: "#28a745" }}>충분</span>,{" "}
            <span style={{ color: "orange" }}>보통</span>,{" "}
            <span style={{ color: "#dc3545" }}>부족</span>,{" "}
            <span style={{ color: "gray" }}>품절</span>
          </li>
          <li>
            품절표시가 없는 상품인데 재고수량이 0일 경우, 바로 입고가 되어 출고
            가능한 제품입니다.
          </li>
          <li>
            품절표시가 되어있는데 재고수량이 있는경우 해당재고가 모두 소진되면
            품절인 제품입니다.
          </li>
          <li>
            특정상품에 대해 주문이 몰릴경우에는 전산에 재고가 있는 것으로
            표시되어있어도 업데이트전까지는 실제 수량이 부족할 수 있습니다.
          </li>
        </div>
      </Alert>

      <Row className="">
        <Col>
          {data.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">No</th>
                  <th className="text-center">제품코드</th>
                  <th className="text-center">제품명</th>
                  <th className="text-center">공급가</th>
                  <th className="text-center">소매판매가(1개)</th>
                  <th className="text-center">2개</th>
                  <th className="text-center">3개</th>
                  <th className="text-center">4개</th>
                  <th className="text-center">5개</th>
                  <th className="text-center">6개 이상</th>
                  <th className="text-center">특이사항</th>
                  <th className="text-center">재고상태</th>
                </tr>
              </thead>
              <tbody>
                {data.map((product, idx) => (
                  <tr key={product.product_code}>
                    <td className="text-center">{idx + 1}</td>
                    <td className="text-center">{product.product_code}</td>
                    <td style={{ textAlign: "left" }}>{product.en_name}</td>
                    <td className="text-center">{product.price}</td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over1)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over2)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over3)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over4)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over5)}
                    </td>
                    <td className="text-center">
                      {addCommasToNumber(product.retail_price_over6)}
                    </td>
                    <td className="text-center">
                      {product.product_status === "판매중"
                        ? ""
                        : product.product_status}
                    </td>
                    {/* 특정 제품의 경우 재고가 충반해 보이도록 예외처리 */}
                    {product.en_name.includes("PURE VITALITY") ? (
                      <td
                        className="text-center"
                        style={{
                          backgroundColor: "#28a745",
                        }}
                      ></td>
                    ) : (
                      <td
                        className="text-center"
                        style={{
                          backgroundColor:
                            product.stock_status === "empty"
                              ? "lightgrey"
                              : product.stock_status,
                        }}
                      >
                        {product.stock_status === "empty" && "0"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h3 className="">브랜드</h3>{" "}
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ width: "100%" }} className="mt-3 text-center">
            <Nav tabs style={{ width: "100%" }} className="text-center">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => toggle("1")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "1" && "700" }}
                    className="tab-title"
                  >
                    PETER&JOHN
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggle("2")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "2" && "700" }}
                    className="tab-title"
                  >
                    MOREVER
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => toggle("3")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "3" && "700" }}
                    className="tab-title"
                  >
                    MANUKA SCIENCE
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => toggle("4")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "4" && "700" }}
                    className="tab-title"
                  >
                    REDWOODS
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => toggle("5")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "5" && "700" }}
                    className="tab-title"
                  >
                    JYP
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "6" ? "active" : ""}
                  onClick={() => toggle("6")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "6" && "700" }}
                    className="tab-title"
                  >
                    NEWZEAL
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "7" ? "active" : ""}
                  onClick={() => toggle("7")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "7" && "700" }}
                    className="tab-title"
                  >
                    VENUSTAS
                  </span>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={activeTab === "8" ? "active" : ""}
                  onClick={() => toggle("8")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "8" && "700" }}
                    className="tab-title"
                  >
                    GREENIA
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "9" ? "active" : ""}
                  onClick={() => toggle("9")}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    style={{ fontWeight: activeTab === "9" && "700" }}
                    className="tab-title"
                  >
                    GREENTOP
                  </span>
                </NavLink>
              </NavItem> */}
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">{handleShow("peter&john")}</TabPane>
              <TabPane tabId="2">{handleShow("morever")}</TabPane>
              <TabPane tabId="3">{handleShow("manuka science")}</TabPane>
              <TabPane tabId="4">{handleShow("redwoods")}</TabPane>
              <TabPane tabId="5">{handleShow("jyp")}</TabPane>
              <TabPane tabId="6">{handleShow("newzeal")}</TabPane>
              <TabPane tabId="7">{handleShow("venustas")}</TabPane>
              {/* <TabPane tabId="8">{handleShow("greenia")}</TabPane>
              <TabPane tabId="9">{handleShow("greentop")}</TabPane> */}
            </TabContent>
          </div>
        </Col>
      </Row>
    </NormalLayout>
  );
}

export default Product;
