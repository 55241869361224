import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";

function AdminSearchForm({ target, setFilteredOrders, token }) {
  const [searchKeyword, setSearchKeyword] = useState({
    ordersNo: "",
    wholesaler: "",
    receiver: "",
    hawb: "",
    pcc: "",
    memo: "",
    startDate: "",
    endDate: "",
  });

  const resetForm = () => {
    setSearchKeyword({
      ordersNo: "",
      wholesaler: "",
      receiver: "",
      hawb: "",
      pcc: "",
      memo: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchKeyword((prevKeyword) => ({
      ...prevKeyword,
      [name]: value,
    }));
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/search?status=${target}`,
        {
          params: searchKeyword,
          headers: {
            Authorization: token,
          },
        }
      );

      const data = response.data;

      if (data.length > 0) {
        setFilteredOrders(data);
      } else {
        alert("일치하는 정보가 없습니다.");
        setFilteredOrders([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <div className="p-3  mb-3 border bg-light">
      <Form onSubmit={handleSearch}>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="ordersNo">주문번호</Label>
              <Input
                type="text"
                name="ordersNo"
                id="ordersNo"
                placeholder="주문번호"
                value={searchKeyword.ordersNo}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="wholesaler">업체명</Label>
              <Input
                type="text"
                name="wholesaler"
                id="wholesaler"
                placeholder="업체명"
                value={searchKeyword.wholesaler}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="receiver">수취인 정보</Label>
              <Input
                type="text"
                name="receiver"
                id="receiver"
                placeholder="수취인의 이름, 주소, 전화번호"
                value={searchKeyword.receiver}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="pcc">개인통관부호</Label>
              <Input
                type="text"
                name="pcc"
                id="pcc"
                placeholder="개인통관부호"
                value={searchKeyword.pcc}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>{" "}
          <Col md={3}>
            <FormGroup>
              <Label for="hawb">운송장번호</Label>
              <Input
                type="text"
                name="hawb"
                id="hawb"
                placeholder="운송장번호"
                value={searchKeyword.hawb}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="memo">주문처리 메모</Label>
              <Input
                type="text"
                name="memo"
                id="memo"
                placeholder="주문처리 메모"
                value={searchKeyword.memo}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="startDate">시작일</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="시작일"
                value={searchKeyword.startDate}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>{" "}
          <Col md={3}>
            <FormGroup>
              <Label for="endDate">종료일</Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                placeholder="종료일"
                value={searchKeyword.endDate}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-end">
            <Button color="secondary" onClick={() => resetForm()}>
              초기화
            </Button>{" "}
            <Button type="submit" color="primary">
              검색
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AdminSearchForm;
