import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom/dist";
import logo from "../../../src/assets/img/logo.png";

const Header = () => {
  const { user, signOut } = useContext(AuthContext);

  const handleSignOut = () => {
    signOut();
  };

  return (
    <Navbar
      expand="ls"
      className="p-3"
      style={{ background: "#343a40", borderColor: "#343a40" }}
    >
      {/* <NavbarBrand className="text-light">
      
      </NavbarBrand> */}
      <Link
        className="bold_600"
        to="/"
        style={{
          color: "whitesmoke",
          textDecoration: "none",
          fontSize: "18px",
        }}
      >
        {/* Megamall */}
        <img src={logo} alt="로고" width="120px" />
      </Link>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <span className="p-3 text-white">
            Kia Ora! {user && user.profile && `${user.profile}`}
            {user && user.name}{" "}
            {process.env.REACT_APP_MODE === "로컬" && "[로컬모드]"}
          </span>
          <Button color="danger" onClick={signOut}>
            로그아웃
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
