import { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import {
  Container,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/layout/Footer";
import logo from "../../src/assets/img/logo.png";
import slogan from "../../src/assets/img/img_slogan.svg";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { user, signIn } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signIn(email, password);
    } catch (err) {
      alert(
        " 아이디 또는 비밀번호를 잘못 입력했습니다. \n 입력하신 내용을 다시 확인해주세요."
      );
      // setError(err.message);
    }
  };

  // If the user is logged in, don't show the login form
  if (user) {
    // Redirect to the profile page
    return <Navigate to="/" />;
  }

  return (
    <>
      {/* <Container className="login-container">
        <div className="text-center">
          <h3 className="mb-3">Mega 주문서 업로드</h3>
          <div className="p-5 square border border-primary rounded">
            {error && <Alert className="mt-3">{error}</Alert>}
            <h2 className="mb-3">로그인</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="email">이메일</Label>
                <Input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">비밀번호</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
              <Button color="primary" block>
                로그인
              </Button>
              <div className="p-3 text-center">
                <Link to="/forgot-password">비밀번호 재설정</Link>
              </div>
            </Form>
          </div>
        </div>
      </Container> */}
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Row className="w-100">
          <Col>
            <div className="text-center p-5">
              <a href="/">
                <img src={slogan} style={{ width: "100%" }} alt="slogan" />
              </a>
            </div>
          </Col>
          <Col className="text-center">
            <div className="p-5 border rounded">
              <img src={logo} alt="로고" />

              <Form
                className="register-form text-start mt-4"
                onSubmit={handleSubmit}
              >
                <label>이메일</label>
                <InputGroup className="form-group-no-border">
                  <Input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <label className="mt-3">비밀번호</label>
                <InputGroup className="form-group-no-border">
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <Button
                  block
                  className="btn-round, mt-3"
                  color="danger"
                  type="submit"
                  size="lg"
                >
                  로그인
                </Button>
              </Form>
              <div className="forgot mt-3">
                <Link to="/forgot-password">비밀번호 재설정</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="p-2"></div>
      <Footer />
    </>
  );
}
