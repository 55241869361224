import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

const CompanyForm = ({
  fetchCompanies,
  selectedCompany,
  setSelectedCompany,
  setShow,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [wholesaler, setWholesaler] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerTel, setManagerTel] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [grade, setGrade] = useState("");
  const [memo, setMemo] = useState("");

  useEffect(() => {
    if (selectedCompany) {
      setCompanyName(selectedCompany.company_name);
      setWholesaler(selectedCompany.wholesaler);
      setManagerName(
        selectedCompany.manager_name !== null
          ? selectedCompany.manager_name
          : ""
      );
      setManagerTel(
        selectedCompany.manager_tel !== null ? selectedCompany.manager_tel : ""
      );
      setManagerEmail(selectedCompany.manager_email);
      setGrade(selectedCompany.purchase_grade);
      setMemo(
        selectedCompany.company_memo !== null
          ? selectedCompany.company_memo
          : ""
      );
    } else {
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setMemo("");
    }
  }, [selectedCompany]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCompany) {
        await axios.put(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company/${selectedCompany.company_index}`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            memo,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            memo,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      }
      fetchCompanies();
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setMemo("");
      setSelectedCompany(null);
      setShow(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyName">회사 이름(사업자등록증 기준)</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="wholesaler">업체명(주문서 작성용)</Label>
                  <Input
                    type="text"
                    name="wholesaler"
                    id="wholesaler"
                    value={wholesaler}
                    onChange={(e) => setWholesaler(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerName">담당자명</Label>
                  <Input
                    type="text"
                    name="managerName"
                    id="managerName"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerTel">전화번호</Label>
                  <Input
                    type="text"
                    name="managerTel"
                    id="managerTel"
                    value={managerTel}
                    onChange={(e) => setManagerTel(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerEmail">이메일</Label>
                  <Input
                    type="managerEmail"
                    name="managerEmail"
                    id="managerEmail"
                    value={managerEmail}
                    onChange={(e) => setManagerEmail(e.target.value)}
                    required
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="grade">그룹</Label>
                  <Input
                    type="select"
                    name="grade"
                    id="grade"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    required
                  >
                    <option value="">선택...</option>
                    <option value="korg">KORG</option>
                    <option value="kor">KOR</option>
                    <option value="locaa">LOCAA</option>
                    <option value="loca">LOCA</option>
                    <option value="ventus">VENTUS</option>
                    <option value="vip">VIP</option>
                    <option value="online">ONLINE</option>
                  </Input>
                </FormGroup>{" "}
              </Col>
            </Row>
            <FormGroup>
              <Label for="memo">메모</Label>
              <Input
                type="memo"
                name="memo"
                id="memo"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </FormGroup>
            <Button type="submit" color="primary">
              도매업체 {selectedCompany ? "정보 수정" : "추가"}
            </Button>{" "}
            <Button color="secondary" onClick={() => setShow(false)}>
              취소
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyForm;
